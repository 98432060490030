.about__container {
  grid-template-columns: 3fr 9fr;
  column-gap: 1.875rem;
}

.about__img {
  height: 14rem;
  box-shadow: inset 200px 200px 200px 200px rgba(205, 207, 212, 0.4);
  animation: profile_animate 5s ease-in-out infinite;
}

@keyframes profile_animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}
.about__data {
  padding: 1.875rem;
  background-color: var(--container-color);
  box-shadow:  0px 10px 25px 0px rgb(69 67 96 / 20%);
  border-radius: var(--border-radius);
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.875rem;
  align-items: flex-start;
  position: sticky;
  transition: all 0.1s ease-in-out;
  
}

.about__data::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid var(--container-color);
  position: absolute;
  left: -0.93rem;
  top: 20%;
}

.about__data:hover {
  background-color: rgb(251, 251, 253);
  box-shadow:  0px 10px 25px 0px rgb(69 67 96 / 70%);
}

.about__description {
  margin-bottom: 1rem;
  text-align: left;
}

.about__skills {
  row-gap: 1.25rem;
}

.skills__titles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.skills__number {
  line-height: 1.2;
}

.skills__bar,
.skills__percentage {
  height: 7px;
  border-radius: 0.25rem;
}

.skills__bar {
  background-color: #f1f1f1;
}

.skills__percentage {
  display: block;
}

.development {
  width: 90%;
  background-color: rgb(255, 209, 92);
}

.ui__design {
  width: 90%;
  background-color: rgb(255, 76, 96);
}

.photography {
  width: 90%;
  background-color: rgb(108, 108, 299);
}


.btn:hover{
  border: 1px solid #2162ee;
  box-shadow: 0px 0px 4px 4px hsla(324, 100%, 2%, 0.664);
  background-color: #2162ee; 
}



@media screen and (max-width: 1024px) {
  .about__container {
      grid-template-columns: 720px;
      justify-content: center;
      row-gap: 1.875rem;
  }

  .about__data::before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    left: 49%;
    top: -20px;
  }

  .about__img {
    justify-self: center;
  }

  .about__box {
    flex-direction: column;
    row-gap: .5rem;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .about__container {
    grid-template-columns: 350px;
  }

  .about__data::before {
    left: 47%;
  }

  .about__data {
    grid-template-columns: 1fr;
    row-gap: 1.875rem;
  }

  .about__boxes {
    grid-template-columns: repeat(2, 150px);
    row-gap: 1.5rem;
    justify-content: center;
  }
}


@media screen and (max-width: 576px) {
  .about__container {
    grid-template-columns: 320px;
  }
}

@media screen and (max-width: 350px) {
  .about__container {
    grid-template-columns: 1fr;
  }

  .about__boxes {
    grid-template-columns: 1fr;
  }

}