@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.app-body {
  font-family: Raleway, sans-serif;
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  background: #1b1523;
  color: white;
  height: 100vh;
}
.App {
  max-width: 860px;
  margin: auto;
}

.App h1 {
  color: white;
}

button {
  background: none;
  border: 2px solid white;
  padding:  6px 12px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
  margin-top: 20px;
}

button:hover {
  background: #c23866;
  color: white;
}

.card-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.App .fa-home {
  position: absolute;
  left: 5%;
  top: 5%;
  transition: all ease-in .2s;
}

.App .fa-home:hover {
  color: red;
  cursor: point;
}
